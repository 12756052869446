import { Directive, OnDestroy } from '@angular/core';
import { BehaviorSubject, concatMap, filter, map, Observable, Subscription, tap, timer } from 'rxjs';
import { VendorInvoiceModalComponentService } from '../services/modals/vendor-invoice-modal.component.service';

@Directive({
  selector: '[dougsAnimationTimeout]',
  exportAs: 'animationTimeoutDirective',
  standalone: true,
})
export class AnimationTimeoutDirective implements OnDestroy {
  constructor(private readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService) {}

  private readonly disableAnimationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly disableAnimation$: Observable<boolean> = this.disableAnimationSubject.asObservable();

  private subscription?: Subscription;

  private timeLeft = 10;

  startTimer(): void {
    const timerSource: Observable<void> = timer(0, 1000).pipe(
      filter((val): val is number => !!val),
      tap(() => this.timeLeft--),
      filter(() => this.timeLeft <= 0),
      map(() => this.disableAnimationSubject.next(true)),
      concatMap(() => this.vendorInvoiceModalComponentService.updatePrefillStatusOnAnimationEnded()),
    );
    this.subscription = timerSource.subscribe();
  }

  stopTimer(): void {
    this.timeLeft = 10;
    this.subscription?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
