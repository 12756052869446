<ng-container *ngIf="vendorInvoiceModalComponentService.formGroupValueChanges$ | async"></ng-container>
<ng-container *ngIf="vendorInvoiceModalComponentService.vendorInvoiceUpdated$ | async"></ng-container>
<form [formGroup]="vendorInvoiceModalComponentService.formGroup">
  <dougs-vendor-invoice-details-header
    [vendorInvoice]="vendorInvoiceModalComponentService.vendorInvoice$ | async"
    [url]="(vendorInvoiceModalComponentService.vendorInvoice$ | async).filePath | trustRessourceUrl"
  ></dougs-vendor-invoice-details-header>
  <dougs-divider class="mt-16"></dougs-divider>
  <div class="content pb-16">
    <div dougsAnimationTimeout #animationTimeoutDirective="animationTimeoutDirective">
      <ng-container
        *ngIf="
          vendorInvoice.prefillStatus !== VENDOR_INVOICE_PREFILL_STATUS.INITIALISED ||
            (animationTimeoutDirective.disableAnimation$ | async);
          else blankSlateAnimation
        "
      >
        <dougs-vendor-invoice-details-form
          @fadeIn
          [vendorInvoice]="vendorInvoiceModalComponentService.vendorInvoice$ | async"
        ></dougs-vendor-invoice-details-form>
        <h5 @fadeIn class="mx-24">Paiement associé</h5>
        <div @fadeIn class="content__button mx-24 pt-16">
          <dougs-button
            *ngIf="vendorInvoiceModalComponentService.shouldShowOperations$ | async"
            color="primary-light"
            [size]="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin ? 'small' : 'medium'"
            (click)="attachOperation.emit()"
          >
            <i class="fas color-primary fa-plus-circle mr-4"></i>
            Attacher une opération
          </dougs-button>
          <dougs-button
            color="primary-light"
            [disabled]="vendorInvoiceModalComponentService.addOperationButtonDisabled$ | async"
            [dougsTooltip]="
              (vendorInvoiceModalComponentService.addOperationButtonDisabled$ | async)
                ? addOperationDisabledTooltip
                : addOperationTooltip
            "
            size="small"
            (click)="createExpenseOperation.emit()"
          >
            <i class="fas color-primary fa-plus-circle mr-4"></i>
            Saisir la note de frais
          </dougs-button>
          <dougs-button
            *ngIf="vendorInvoiceModalComponentService.canAddVendorInvoiceOperation$ | async"
            color="admin"
            [disabled]="vendorInvoiceModalComponentService.addOperationButtonDisabled$ | async"
            dougsTooltip="Date et montant en euros requis; les factures n'incluent pas les remboursements"
            [dougsTooltipDisable]="!(vendorInvoiceModalComponentService.addOperationButtonDisabled$ | async)"
            size="small"
            (click)="createVendorInvoiceOperation.emit()"
          >
            <i class="fas color-white fa-plus-circle mr-4"></i>
            Saisir la facture
          </dougs-button>
        </div>
        <dougs-loader *ngIf="vendorInvoiceModalComponentService.isLoadingOperations$ | async"></dougs-loader>
        <dougs-vendor-invoice-details-operations
          [vendorInvoice]="vendorInvoiceModalComponentService.vendorInvoice$ | async"
        ></dougs-vendor-invoice-details-operations>
      </ng-container>
      <ng-template #blankSlateAnimation>
        <div @fadeOut class="analyzing-animation mt-80 mb-56">
          <p class="small mb-16">Récupération des informations de la facture en cours...</p>
          <ng-lottie
            (animationCreated)="animationTimeoutDirective.startTimer()"
            (destroy)="animationTimeoutDirective.stopTimer()"
            [options]="animationOptions"
            containerClass="animation-box"
            width="150px"
            height="150px"
          ></ng-lottie>
        </div>
      </ng-template>
    </div>
  </div>
</form>
