import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LottieComponent } from 'ngx-lottie';
import { ConfigBackService } from '@dougs/core/config-back';
import {
  BlurOnEnterDirective,
  CheckboxComponent,
  ControlFormFieldDirective,
  CurrencySuffixComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
  SuffixFormFieldDirective,
  TooltipDirective,
  TrackByPipe,
} from '@dougs/ds';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { AnimationTimeoutDirective } from '../../../directives/vendor-invoice-animation-timeout.directive';
import { VendorInvoiceModalComponentService } from '../../../services/modals/vendor-invoice-modal.component.service';

const ANIMATION_DURATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-vendor-invoice-details-form',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponent,
    SelectComponent,
    SelectOptionComponent,
    InputDatepickerComponent,
    SuffixFormFieldDirective,
    CheckboxComponent,
    LottieComponent,
    ReactiveFormsModule,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    BlurOnEnterDirective,
    AnimationTimeoutDirective,
    TrackByPipe,
    CurrencySuffixComponent,
    TooltipDirective,
  ],
  templateUrl: './vendor-invoice-details-form.component.html',
  styleUrls: ['./vendor-invoice-details-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorInvoiceDetailsFormComponent {
  @Input() vendorInvoice!: VendorInvoice;

  constructor(
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public readonly configBackService: ConfigBackService,
  ) {}

  protected readonly invoiceDetailTooltip = `Nous récupérons les informations clés de vos factures pour simplifier le suivi de vos dépenses en identifiant automatiquement les paiements. Ces données n'ont pas d'impact sur votre comptabilité.`;
}
