import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { CompanyStateService } from '@dougs/company/shared';
import {
  BlurOnEnterDirective,
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  DividerComponent,
  DRAWER_DATA,
  DrawerCloseDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  MemoComponent,
  SelectComponent,
  SelectOptionComponent,
  ShowOperationDividerPipe,
  SuffixFormFieldDirective,
  TooltipDirective,
  TrackByPipe,
  TrustRessourceUrlPipe,
} from '@dougs/ds';
import { OPERATION_STATE_TOKEN, VendorInvoiceOperationsStateService } from '@dougs/operations/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OperationCompactDirective } from '@dougs/operations/ui';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { VendorInvoice, VendorInvoicePrefillStatus } from '@dougs/vendor-invoice/dto';
import { AnimationTimeoutDirective } from '../../directives/vendor-invoice-animation-timeout.directive';
import { VendorInvoiceModalComponentService } from '../../services/modals/vendor-invoice-modal.component.service';
import { VendorInvoiceOperationComponent } from '../vendor-invoice-operation-list/vendor-invoice-operation/vendor-invoice-operation.component';
import { VendorInvoiceDetailsFormComponent } from './vendor-invoice-details-form/vendor-invoice-details-form.component';
import { VendorInvoiceDetailsHeaderComponent } from './vendor-invoice-details-header/vendor-invoice-details-header.component';
import { VendorInvoiceDetailsOperationsComponent } from './vendor-invoice-details-operations/vendor-invoice-details-operations.component';

const ANIMATION_DURATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-vendor-invoice-details',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ControlFormFieldDirective,
    DividerComponent,
    DrawerCloseDirective,
    FormFieldComponent,
    FormsModule,
    LabelFormFieldDirective,
    LoaderComponent,
    MemoComponent,
    ReactiveFormsModule,
    SelectComponent,
    SelectOptionComponent,
    SuffixFormFieldDirective,
    TrackByPipe,
    VendorInvoiceOperationComponent,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownOptionComponent,
    OperationCompactDirective,
    ShowOperationDividerPipe,
    BlurOnEnterDirective,
    InputDatepickerComponent,
    CheckboxComponent,
    VendorInvoiceDetailsHeaderComponent,
    VendorInvoiceDetailsFormComponent,
    VendorInvoiceDetailsOperationsComponent,
    TooltipDirective,
    TrustRessourceUrlPipe,
    AnimationTimeoutDirective,
    LottieComponent,
  ],
  providers: [{ provide: OPERATION_STATE_TOKEN, useExisting: VendorInvoiceOperationsStateService }],
  templateUrl: './vendor-invoice-details.component.html',
  styleUrls: ['./vendor-invoice-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(ANIMATION_DURATION, style({ opacity: 0 })),
        animate(ANIMATION_DURATION, style({ opacity: 1 })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({
          opacity: 1,
          position: 'absolute',
          top: 0,
          width: '100%',
        }),
        animate(ANIMATION_DURATION, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class VendorInvoiceDetailsComponent {
  @Output() attachOperation = new EventEmitter<void>();
  @Output() createVendorInvoiceOperation = new EventEmitter<void>();
  @Output() createExpenseOperation = new EventEmitter<void>();

  constructor(
    @Inject(DRAWER_DATA) public readonly vendorInvoice: VendorInvoice,
    public readonly vendorInvoiceModalComponentService: VendorInvoiceModalComponentService,
    public readonly vendorInvoiceOperationsStateService: VendorInvoiceOperationsStateService,
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly userStateService: UserStateService,
  ) {}

  animationOptions: AnimationOptions = {
    path: 'animations/facture.json',
  };
  protected readonly VENDOR_INVOICE_PREFILL_STATUS = VendorInvoicePrefillStatus;
  protected readonly addOperationTooltip: string =
    'Saisissez en note de frais les dépenses effectuées avec votre argent personnel.';
  protected readonly addOperationDisabledTooltip: string =
    "Date et montant en euros requis; les notes de frais n'incluent pas les remboursements";
}
